<template>
  <div class='mt-3 w-100'>
    <b-card class='w-100'>
      <template v-slot:header>
        <h1>
          <span :class="{ hidden: !redefineDisabled }">
            <img src="/img/loading.gif" />
          </span>
          REDEFINIR SENHA
        </h1>
      </template>
      <b-form>
        <b-alert show variant="success" :class="{ hidden: success === '' }">{{
          success
        }}</b-alert>
        <b-input-group>
          <b-form-input
            id="current-password"
            :type="showCurrentPassword ? 'text' : 'password'"
            v-model="currentPassword"
            :disabled="redefineDisabled"
            placeholder="Senha Atual*"
            required
          ></b-form-input>

          <b-input-group-append class='mt-4 pa-1' style='cursor: pointer' @click='showCurrentPassword = !showCurrentPassword'>
            <b-icon v-if='showCurrentPassword' font-scale="1.5" icon="eye-slash-fill"></b-icon>
            <b-icon v-else font-scale="1.5" class='pa-1' icon="eye-fill"></b-icon>
          </b-input-group-append>
        </b-input-group>

        <b-input-group>
          <b-form-input
            id="password"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            :disabled="redefineDisabled"
            placeholder="Nova Senha*"
            required
          ></b-form-input>

          <b-input-group-append class='mt-4 pa-1' style='cursor: pointer' @click='showPassword = !showPassword'>
            <b-icon v-if='showPassword' font-scale="1.5" icon="eye-slash-fill"></b-icon>
            <b-icon v-else font-scale="1.5" class='pa-1' icon="eye-fill"></b-icon>
          </b-input-group-append>
        </b-input-group>

        <b-input-group>
          <b-form-input
            id="password-confirm"
            :type="showPasswordConfirm ? 'text' : 'password'"
            v-model="passwordConfirm"
            :disabled="redefineDisabled"
            placeholder="Confirme sua nova senha*"
            required
          ></b-form-input>

          <b-input-group-append class='mt-4 pa-1' style='cursor: pointer' @click='showPasswordConfirm = !showPasswordConfirm'>
            <b-icon v-if='showPasswordConfirm' font-scale="1.5" icon="eye-slash-fill"></b-icon>
            <b-icon v-else font-scale="1.5" class='pa-1' icon="eye-fill"></b-icon>
          </b-input-group-append>
        </b-input-group>

        <secure-password-checker :password='password'/>
        <b-alert show variant="danger" :class="{ hidden: !hasErrors() }">
          <ul class="errorMessages">
            <li v-if="errors.password">Senha inválida</li>
            <li v-if="errors.passwordConfirm">Senha não confere</li>
            <li v-if="errors.emptyfields">
              Existem campos obrigatórios vazios
            </li>
          </ul>
        </b-alert>
        <b-alert show variant="danger" :class="{ hidden: error === '' }">{{
          error
        }}</b-alert>
        <b-input-group>
          <b-button
            id="signup-button"
            block
            @click="redefine"
            class="mb-2 mb-sm-2"
            :disabled="hasErrors() || redefineDisabled"
            >REDEFINIR</b-button
          >
        </b-input-group>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

const regPwd = new RegExp(
  '(^$|^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}))'
)
import SecurePasswordChecker from '@/components/SecurePasswordChecker/SecurePasswordChecker'
export default {
  name: 'Signup',
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Redefinir senha',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  components: {
    SecurePasswordChecker,
  },
  computed: {
    errors() {
      return {
        passwordConfirm: this.password !== this.passwordConfirm,
        password: !regPwd.test(this.password),
      }
    },
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      currentPassword: '',
      showCurrentPassword: false,
      showPassword: false,
      showPasswordConfirm: false,
      error: '',
      success: '',
      redefineDisabled: false,
    }
  },
  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    hasErrors() {
      return (
        this.errors.currentPassword ||
        this.errors.passwordConfirm ||
        this.errors.password ||
        this.errors.emptyfields
      )
    },
    clearForm() {
      this.password = ''
      this.passwordConfirm = ''
      this.currentPassword = ''
    },
    redefine() {
      this.redefineDisabled = true
      if (this.$store.getters['user/current']) {
        if (
          this.currentPassword === '' ||
          this.password === '' ||
          this.passwordConfirm === ''
        ) {
          this.redefineDisabled = false
          return (this.error = 'Existem campos obrigatórios vazios')
        } else {
          this.$api
            .post('users/redefinePwd', {
              username: this.$store.getters['user/current'].username,
              currentPassword: this.currentPassword,
              password: this.password,
            })
            .then((res) => {
              if (res.data.error) {
                this.redefineDisabled = false
                this.error = res.data.error
              } else {
                this.redefineDisabled = false
                this.success = res.data.message
                this.$router.push({ name: 'LegalSearch' })
              }
              this.clearForm()
            })
            .catch((err) => {
              if (err.response.status === 401)
                this.error = 'Senha atual incorreta'

              this.redefineDisabled = false
              this.clearForm()
            })
        }
      } else {
        this.$router.push({ name: 'Signin' })
      }
    },
  },
  watch: {},
}
</script>

<style>
.hidden {
  display: none;
}
.errorMessages {
  list-style: none;
  margin: 0px;
}
</style>

<style scoped>
#logo-image {
  padding: 60px 0;
}

.card-header h1 {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

div.card {
  border-radius: 0;
  /*box-shadow: 4px 6px 20px 0px #888888;*/
  width: 100%;
  margin: 0 auto;
}

.card-body input {
  border: 0;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
  margin: 20px 0;
  padding-top: 18px;
  padding-left: 0;
}
.card-body input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaaaaa;
  opacity: 1; /* Firefox */
}

.card-body input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aaaaaa;
}

.card-body input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aaaaaa;
}

button#signup-button {
  margin-top: 20px;
  font-weight: 500;
  color: var(--white);
  border-radius: 0;
  background-color: #ccdb3a;
  border: none;
}
</style>
